import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TakeActionComponent = ({ titleOnly, atWork, atSchool, inCommunity }) => {

  const data = useStaticQuery(graphql`
    query {
      allWordPressAcfCallout(limit: 3) {
        nodes {
          title
          body
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  let atSchoolData = data.allWordPressAcfCallout.nodes.filter(node => node.title === 'At School')[0];
  let atWorkData = data.allWordPressAcfCallout.nodes.filter(node => node.title === 'At Work')[0];
  let inCommunityData = data.allWordPressAcfCallout.nodes.filter(node => node.title === 'In My Community')[0];
  
  return (
    <div className="columns is-gapless take-action mb0">
      { atWorkData &&
        <div className="column">
          <div className={atWork ? 'panel-link active' : 'panel-link'} >
            <Link to="takeaction/at-work" className="takemenu" activeClassName="active" state={{ active: 'at-work'}}>
              <figure className="takeactionpic"> 
                <Img fluid={atWorkData.image.localFile.childImageSharp.fluid} fadeIn={true} className="zoomable" alt="OklahomaStandard-TakeAction-AtSchool" />
              </figure>

              <div className="panel-link__body has-background-link actionfield has-text-white">
                <h2 className="is-size-2 has-text-weight-bold is-uppercase">
                  {atWorkData.title}
                </h2> 
                {titleOnly &&
                  <p className="is-size-4 mb2 mt3 taresize">
                    {atWorkData.body}
                  </p>    
                }
                {titleOnly &&
                  <button className="button butpos linkish is-outlined is-white">
                    Take Action
                  </button>
                }
              </div>
            </Link>
          </div>
        </div>
      }
      { atSchoolData &&
        <div className="column">
          <div className={atSchool ? 'panel-link active' : 'panel-link'} >
            <Link to="takeaction/at-school" className="takemenu" activeClassName="active" state={{ active: 'at-school'}}>
            <figure className="takeactionpic">
              <Img fluid={atSchoolData.image.localFile.childImageSharp.fluid} fadeIn={true} className="zoomable"  alt="OklahomaStandard-TakeAction-AtWork"/>
            </figure>

            <div className="panel-link__body has-background-danger actionfield has-text-white">
              <h2 className="is-size-2 has-text-weight-bold is-uppercase">{atSchoolData.title}</h2> 
              {titleOnly &&
                <p className="is-size-4 mb3 mt3 taresize">
                  {atSchoolData.body}
                </p>        
              }
              {titleOnly &&
                <button className="button butpos linkish is-outlined is-white">
                  Take Action
                </button>
              }
            </div>
            </Link>
          </div>
        </div>
      }
      { inCommunityData &&
        <div className="column">
          <div className={inCommunity ? 'panel-link active' : 'panel-link'} >
            <Link to="takeaction/in-community" className="takemenu" activeClassName="active" state={{ active: 'in-community'}}>
            <figure className="takeactionpic">
              <Img fluid={inCommunityData.image.localFile.childImageSharp.fluid} fadeIn={true} className="zoomable"  alt="OklahomaStandard-TakeAction-InCommunity"/>
            </figure>
            <div className="panel-link__body has-background-primary actionfield has-text-white">
              <h2 className="is-size-2 has-text-weight-bold is-uppercase">{inCommunityData.title}</h2> 
              {titleOnly &&
                <p className="is-size-4 mb1 mt3  taresize">
                  {inCommunityData.body}
                </p>        
              }
              {titleOnly &&
                <button className="button butpos linkish is-outlined is-white">
                  Take Action
                </button>
              }
            </div>
            </Link>
          </div>
        </div>
      }
    </div>
  )
}

TakeActionComponent.defaultProps = {
  titleOnly: true,
  spanOnly: true,
  atWork: false,
  atSchool: false,
  inCommunity: false,
}

TakeActionComponent.propTypes = {
  titleOnly: PropTypes.bool,
  atWork: PropTypes.bool,
  atSchool: PropTypes.bool,
  inCommunity: PropTypes.bool,
}

export default TakeActionComponent
