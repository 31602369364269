import { Link } from "gatsby"
import logo_preserved from "../images/OklahomaStandard-PreservedBy-White.svg"
import logo_badge from "../images/OklahomaStandard-Badge-White.svg"
import React from "react"

const Footer = () => (
   <footer className="footer has-background-primary has-text-white is-relative">
      
      <div className="columns">
        <div className="column is-four-fifths">

          <div className="column">
           <ul className="is-size-5 has-text-white is-uppercase has-text-weight-semibold">
              <li>
                <Link to="about">
                  About
                </Link>
              </li>
              <li>
                <Link to="takeaction/at-work">
                  Take Action
                </Link>
              </li>
              <li>
                <a href="http://memorialmuseum.com/shop" className="">Shop</a>
              </li>
              <li>
                <a href="https://memorialmuseum.com/press-room/" className="">Press</a>
              </li>
            </ul>  
          </div>
          <div className="column">
            <p className="is-uppercase is-size-8">
              © {new Date().getFullYear()} Oklahoma Standard.
            </p>
          </div>
        </div>
  
        <div className="column is-one-fifths">
          <div className="columns is-mobile mt4">
            <div className="column is-half has-text-right	zin">
              <a href="https://memorialmuseum.com/" target="_blank" rel="noopener noreferrer">
                <img class="muswid" src={logo_badge} width="141px" alt=""/>
              </a>
            </div>
            <div className="column is-half has-text-right">
              <a href="https://memorialmuseum.com/" target="_blank" rel="noopener noreferrer">
                <img class="musright muswid" src={logo_preserved} width="95px"  alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="c-rectangle c-rectangle--center-middle c-rectangle--blue">
        <svg width="300px" height="25px" viewBox="0 0 300 25">
          <g id="Page-1" stroke="none" strokeWidth="1" fillRule="nonzero">
            <rect id="Rectangle" x="0" y="0" width="300" height="25"></rect>
          </g>
        </svg>
      </div>

      <div className="c-rectangle c-rectangle--top-right c-rectangle--white">
        <svg width="300px" height="25px" viewBox="0 0 300 25">
          <g id="Page-1" stroke="none" strokeWidth="1" fillRule="nonzero">
            <rect id="Rectangle" x="0" y="0" width="300" height="25"></rect>
          </g>
        </svg>
      </div>

      <div className="c-rectangle c-rectangle--bottom-right c-rectangle--red">
        <svg width="300px" height="25px" viewBox="0 0 300 25">
          <g id="Page-1" stroke="none" strokeWidth="1" fillRule="nonzero">
            <rect id="Rectangle" x="0" y="0" width="300" height="25"></rect>
          </g>
        </svg>
      </div>
    </footer>
  )

export default Footer
