import { Link } from "gatsby"
import logo from "../images/OklahomaStandard-PrimaryLogo.svg"
import logo_preserved from "../images/OklahomaStandard-PreservedBy-White.svg"
import badge from "../images/OklahomaStandard-Badge-White.svg"
import React from "react"

class Header extends React.Component {
	componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll);

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
  
      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
  
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);
  
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
  
        });
      });
    }
	}

  render() {
    return (
      <header id="js-header" className="navbar is-fixed-top">
        <div className="navbar-brand">
          <Link
            to="/"
            className='navbar-item'
          >
            <img src={logo} alt="Oklahoma Standard" width="300px" />
          </Link>
          <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        
        <div id="navbarBasic" className="navbar-menu">
          <div className="navbar-end">
            <Link to="about" className="navbar-item navitempad" activeClassName="active">
              About
            </Link>
            <Link to="takeaction/at-work" className="navbar-item navitempad" activeClassName="active" partiallyActive={true}>
              Take Action
            </Link>

            <a href="http://memorialmuseum.com/shop" className="navbar-item navitempad is-size-7">
              Shop
            </a>

            <a href="https://memorialmuseum.com/press-room/" className="navbar-item navitempad is-size-7 pressbtn">
              Press
            </a>
            <a href="https://memorialmuseum.com/" target="_blank" rel="noopener noreferrer" className="is-hidden-mobile is-hidden-tablet-only">
              <img src={badge} alt="Service, Honor, Kindness" className="navbar-item badge"/>
            </a>
            <a href="https://memorialmuseum.com/" target="_blank" rel="noopener noreferrer" className="is-hidden-mobile is-hidden-tablet-only">
              <img src={logo_preserved} alt="Oklahoma City National Memorial Museum" className="navbar-item logo_perserve" />
            </a>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
